














import vue from 'vue';

const actions = [
    {
        event: 'revert',
        class: 'revert',
        icon: 'backspace',
        enabled: false,
    },
    {
        event: 'submit',
        class: 'submit',
        icon: 'paper-plane',
        enabled: false,
    },
] as Action[];

interface Action {
    class: string;

    event: 'submit'|'revert';

    icon: string;

    enabled: boolean;
}

export default vue.extend({
    props: {
        submit: {
            type: Boolean,
            required: true,
        },
        revert: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        actions(): Action[] {
            return actions.map(
                (action: Action) => ({
                    ...action,
                    enabled: this[action.event] ?? action.enabled,
                }),
            );
        },
    },
});
