








































import QuestionInput from '@/components/answer-input/QuestionInput.vue';

export default QuestionInput.extend({
    name: 'Numeric',
    data() {
        return {
            data: '',
            valid: this.skipable,
            leftPosition: '',
            color: '',
        };
    },
    computed: {
        value(): number {
            // eslint-disable-next-line
            // @ts-ignore
            return parseFloat(this.data.replace(',', '.') || 0, 10);
        },
        slider(): boolean {
            return this.question.options?.hasSlider || false;
        },
    },
    methods: {
        validateInput() {
            const {options} = this.question;
            this.valid = true;
            if (options.minimum && this.value < Number.parseInt(options.minimum, 10)) {
                this.$emit('error', 'ERROR.VALIDATION.TOO_LOW');
                this.valid = false;
            }
            if (options.maximum && this.value > Number.parseInt(options.maximum, 10)) {
                this.$emit('error', 'ERROR.VALIDATION.TOO_HIGH');
                this.valid = false;
            }

            if (!this.question.options?.hasSlider) {
                let rest = null;
                ['.', ','].forEach((elem) => {
                    if (this.data.indexOf(elem) !== -1) {
                        // eslint-disable-next-line prefer-destructuring
                        rest = this.data.split(elem)[1];
                    }
                });
                // eslint-disable-next-line
                // @ts-ignore
                if ((options.precision === 0 && (rest === '' || rest !== null)) || (rest && rest.length > options.precision)) {
                    this.$emit('error', 'ERROR.VALIDATION.PRECISION');
                    this.valid = false;
                }
            }
            this.$emit('valid', this.valid);
        },
        inputChanged($e: KeyboardEvent): void {
            if ($e.key === 'Enter') {
                this.$nextTick(() => this.$emit('submit'));
                return;
            }

            const keyTest = /^[0-9\\.,-]$/;
            if (!keyTest.test($e.key)) {
                $e.preventDefault();
                $e.stopImmediatePropagation();
                return;
            }

            const test = /^-?[0-9]*[,\\.]?[0-9]*$/;
            if (!test.test(`${this.data}${$e.key}`)) {
                $e.preventDefault();
                $e.stopImmediatePropagation();
            }
        },
        moveValue(event: InputEvent) {
            const control = event.target as HTMLInputElement;
            const controlMin = Number(control.getAttribute('min'));
            const controlMax = Number(control.getAttribute('max'));
            const controlVal = Number(this.data);
            const controlThumbWidth = 25;

            const range = controlMax - controlMin;

            const position = ((controlVal - controlMin) / range) * 100;

            const positionOffset = Math.round((controlThumbWidth * position) / 100) - (controlThumbWidth / 2);
            const output = document.getElementById('numericRangeVal') as HTMLOutputElement;

            this.leftPosition = `calc(${position}% - ${positionOffset}px)`;
            output.innerText = String(controlVal);

            this.colorMove();
        },
        colorMove() {
            const slider = document.getElementById('numericRangeInput') as HTMLInputElement;
            const x = ((Number(this.data) - Number(slider.min)) / (Number(slider.max) - Number(slider.min))) * 100;
            this.color = `linear-gradient(90deg, var(--slider-background-color-left, grey) ${x}%, var(--slider-background-color-right, darkgrey) ${x}%)`;
        },
    },
    mounted() {
        if (this.slider) {
            const slider = this.$el.querySelector('input[type=range]') as HTMLInputElement;
            const controlVal = Number(slider.value);
            this.data = `${controlVal}`;
            this.moveValue({target: document.getElementById('numericRangeInput')} as InputEvent);
            this.validateInput();
        }
    },
});
