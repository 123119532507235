import Vue from 'vue';

import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faBackspace,
    faBars,
    faPaperPlane,
    faTimes,
    faPaperclip,
    faFile,
    faCamera,
    faCaretDown,
    faCaretUp,
    faMagnifyingGlass,
    faUpDown,
    faImage,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

library.add(
    faBars,
    faBackspace,
    faTimes,
    faPaperPlane,
    faPaperclip,
    faFile,
    faCamera,
    faCaretDown,
    faCaretUp,
    faMagnifyingGlass,
    faUpDown,
    faImage,
);

Vue.component('FaIcon', FontAwesomeIcon);
