




















import QuestionInput from '@/components/answer-input/QuestionInput.vue';

export default QuestionInput.extend({
    name: 'Open',
    data() {
        return {
            valid: this.skipable,
            text: '',
        };
    },
    watch: {
        text(value) {
            this.validateInput(value);
        },
    },
    methods: {
        onPressEnter() {
            this.validateInput(this.text);
            if (this.valid) {
                this.$emit('submitForm');
            }
        },
        validateInput(input: string) {
            const {options} = this.question;
            this.valid = true;
            if (options.minimum && input.trim().length < options.minimum) {
                this.$emit('error', 'ERROR.VALIDATION.TOO_SHORT');
                this.valid = false;
            }
            if (options.maximum && input.length > options.maximum) {
                this.$emit('error', 'ERROR_VALIDATION.TOO_LONG');
                this.valid = false;
            }
            this.$emit('valid', this.valid);
        },
    },
});
