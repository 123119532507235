import {padInteger} from '@/helpers/number-utilities';

// eslint-disable-next-line import/prefer-default-export
export function GetDateAsString(date: Date) {
    let str = '';
    str += `${padInteger(date.getDate(), 2)}-`;
    str += `${padInteger(date.getMonth() + 1, 2)}-`;
    str += `${date.getFullYear()} `;
    str += `${padInteger(date.getHours(), 2)}:`;
    str += padInteger(date.getMinutes(), 2);
    return str;
}
