/* eslint @typescript-eslint/no-explicit-any: 0 */
import InstructionModel from '@/models/instruction.model';
import QuestionModel, {QuestionType} from '@/models/question.model';

interface QuestionEncoder {
    (question: QuestionModel, payload: any): any;
}

const passThroughEncoder = (question: QuestionModel, payload: any): any => payload;

const questionEncoders: Record<QuestionType, QuestionEncoder> = {
    date: passThroughEncoder,
    multiple(question: QuestionModel, payload: any): string {
        return JSON.stringify(payload);
    },
    file: passThroughEncoder,
    open: passThroughEncoder,
    email: passThroughEncoder,
    numeric: passThroughEncoder,
    nps: passThroughEncoder,
    emoji: passThroughEncoder,
    choice: passThroughEncoder,
    currency: passThroughEncoder,
};

export default function encodePayload(
    instruction: InstructionModel,
    payload: any,
): any {
    const {question} = instruction;
    let encoded = payload;

    if (question?.type && question.type in questionEncoders) {
        encoded = questionEncoders[question.type](question, payload);
    }

    return encoded;
}
