



























import vue from 'vue';
import CommunityApi from '@/apis/community.api';
import settings from '@/settings';

export default vue.extend({
    name: 'SideMenu',
    methods: {
        closeSideMenu() {
            this.$emit('close');
        },
        handleLuminocityChange(value: string) {
            if (value !== '') {
                const plaincolor = value.substring(1);
                const r = Number.parseInt(plaincolor.substring(0, 2), 16) / 255;
                const g = Number.parseInt(plaincolor.substring(2, 4), 16) / 255;
                const b = Number.parseInt(plaincolor.substring(4, 6), 16) / 255;
                const a = Number.parseInt(plaincolor.substring(6), 16) / 255;

                const L = 0.2126 * r + 0.7152 * g + 0.0722 * b;
                const element = this.$refs.forceVisible as HTMLElement;
                if (L > 0.4 || a < 0.25) {
                    element.style.setProperty('--filter', 'invert()', 'important');
                } else {
                    element.style.setProperty('--filter', 'none', 'important');
                }
            }
        },
        getSideMenuBackgroundColor() {
            if (this.$store.state.styling?.web === undefined) return '#87878787';
            return this.$store.state.styling.web['sidemenu_background-color'] || '#87878787';
        },
    },
    computed: {
        logo() {
            return this.$store.state.styling?.community?.sideLogo
            || this.$store.state.styling?.community?.logo
            || 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        },
        style(): string {
            return this.getSideMenuBackgroundColor();
        },
    },
    watch: {
        style: {
            deep: true,
            handler(value: string) {
                this.handleLuminocityChange(value);
            },
        },
    },
    data() {
        return {
            sidemenuItems: [] as Record<string, string>[],
            version: settings.version,
        };
    },
    created() {
        CommunityApi
            .getSidemenu(this.$route.params.community)
            .then((data) => {
                this.sidemenuItems = data;
            });
    },
    mounted() {
        this.handleLuminocityChange(this.getSideMenuBackgroundColor());
    },
});
