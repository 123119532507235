/* eslint @typescript-eslint/no-explicit-any: 0 */
import CommunityApi from '@/apis/community.api';
import VueRouter from '@/router';
import settings from '@/settings';

export default class CommunityService {
    public static async GetStylingForCommunity(): Promise<Record<string, any> | null> {
        const {community} = VueRouter.currentRoute.params;
        if (community !== undefined) {
            const data = await CommunityApi.getStyle(community);
            if (data.community !== undefined && data.community['font-fam'] !== undefined) {
                let fontName = data.community['font-fam']
                    .replaceAll('font-family:', '')
                    .replaceAll(';', '')
                    .replaceAll('\'', '')
                    .trim();
                if (fontName.indexOf(',') >= 0) fontName = fontName.substr(0, fontName.indexOf(','));
                document.documentElement.style.setProperty('--community-font-fam', `'${fontName}'`);

                if (data.community['font-url'] !== undefined) {
                    if (data.community['font-url'].lastIndexOf(settings.objectStorage) >= 0) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                        // @ts-ignore TS2304
                        const font = new FontFace(fontName, `url(${data.community['font-url']})`);
                        font.load()
                            .then((loadedFont: any) => {
                                (document as any).fonts.add(loadedFont);
                            });
                    } else {
                        const documentHead = document.head;
                        const link = document.createElement('link');
                        link.type = 'text/css';
                        link.rel = 'stylesheet';
                        link.href = data.community['font-url'];
                        documentHead.appendChild(link);
                    }
                }
            }
            if (data.web === undefined) {
                return data;
            }
            Object.entries(data.web)
                .forEach((item) => {
                    const identifier = item[0].replaceAll('_', '-');
                    const value = String(item[1]);

                    if (identifier.indexOf('image') !== -1) {
                        document.documentElement.style.setProperty(`--${item[0].replaceAll('_', '-')}`, `url(${item[1]})`);
                    } else if (/^[1-9]+[0-9]*$/.test(value)) {
                        document.documentElement.style.setProperty(`--${item[0].replaceAll('_', '-')}`, `${item[1]}px`);
                    } else {
                        document.documentElement.style.setProperty(`--${item[0].replaceAll('_', '-')}`, (item[1] as string));
                    }
                });
            return data;
        }
        return null;
    }
}
