
























import QuestionInput from '@/components/answer-input/QuestionInput.vue';

export default QuestionInput.extend({
    name: 'Nps',
    data() {
        return {
            data: '',
            valid: this.skipable,
        };
    },
    computed: {
        value(): number {
            return Number(this.data.replace(',', '.') || '0') || 0;
        },
    },
    methods: {
        validateInput() {
            this.valid = true;
            if (this.value < 0 || this.value > 10) {
                this.$emit('error', 'ERROR.VALIDATION.NPS');
                this.valid = false;
            }
            this.$emit('valid', this.valid);
        },
        moveValue(event: InputEvent) {
            const control = event.target as HTMLInputElement;
            const controlMin = 0;
            const controlMax = 10;
            const controlVal = Number(control.value);
            const controlThumbWidth = 25;
            const range = controlMax - controlMin;

            const position = ((controlVal - controlMin) / range) * 100;
            const positionOffset = Math.round((controlThumbWidth * position) / 100) - (controlThumbWidth / 2);
            const output = document.getElementById('npsRangeVal') as HTMLOutputElement;

            output.style.setProperty('left', `calc(${position}% - ${positionOffset}px)`);
            output.innerText = String(controlVal);

            this.colorMove();
        },
        colorMove() {
            const slider = document.getElementById('npsRangeInput') as HTMLInputElement;
            const x = ((Number(slider.value) - Number(slider.min)) / (Number(slider.max) - Number(slider.min))) * 100;
            const color = `linear-gradient(90deg, var(--slider-background-color-left, grey) ${x}%, var(--slider-background-color-right, darkgrey) ${x}%)`;
            slider.style.background = color;
        },
    },
    mounted() {
        this.moveValue({target: document.getElementById('npsRangeInput')} as InputEvent);
        document.documentElement.style.setProperty('--nps-high-text', `'${this.$t('NPS.HIGH') as string}'`);
        document.documentElement.style.setProperty('--nps-low-text', `'${this.$t('NPS.LOW') as string}'`);
    },
});
