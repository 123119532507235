



































import vue from 'vue';
import SideMenu from '@/components/structure/SideMenu.vue';
import CommunityService from '@/services/community.service';
import Loader from '@/views/Loader.vue';
import {Route} from 'vue-router';
import features from '@/helpers/features';

export default vue.extend({
    name: 'App',
    components: {Loader, SideMenu},
    methods: {
        openSideMenu() {
            this.visible = true;
        },
        closeSideMenu() {
            this.visible = false;
        },
        resizeEventHandler() {
            if (window.innerWidth > 800) {
                this.visible = false;
            }
        },
        handleLoader(loading: boolean) {
            this.isLoading = loading;
        },
        handleProgress(newProgression: number) {
            const pageEl = this.$refs.page as HTMLDivElement;
            pageEl.style.setProperty('--progress', `${newProgression * 100}%`);
        },
        handleChatHeightChange($event: Record<string, number>) {
            const pageEl = this.$refs.page as HTMLDivElement;
            const scrollDist = -$event.scroll;
            const totalHeight = pageEl.clientHeight;
            let heightDifference = Math.abs(totalHeight - $event.chatHeight);
            this.scrollable = heightDifference >= 1;

            let normalizedScroll = 1;
            if (heightDifference < 1) {
                heightDifference = 1;
            }
            normalizedScroll = 1 - Math.max(
                Math.min(
                    ($event.chatHeight - (totalHeight + scrollDist)) / 192,
                    1,
                ),
                0,
            );

            const newHeaderHeight = 80 + (192 - 80) * normalizedScroll;

            window.requestAnimationFrame(() => {
                pageEl.style.setProperty('--header-height', `${newHeaderHeight}px`);
                pageEl.style.setProperty('--header-opacity', `${Math.min(normalizedScroll, 1)}`);
            });
        },
        handleHeaderLuminocityChange(value: string) {
            if (value.length > 0) {
                const plaincolor = value.substring(1);
                const r = Number.parseInt(plaincolor.substring(0, 2), 16) / 255;
                const g = Number.parseInt(plaincolor.substring(2, 4), 16) / 255;
                const b = Number.parseInt(plaincolor.substring(4, 6), 16) / 255;
                const a = Number.parseInt(plaincolor.substring(6), 16) / 255;

                const L = 0.2126 * r + 0.7152 * g + 0.0722 * b;
                const element = document.getElementById('hamburger') as HTMLElement;
                if (!element) {
                    return;
                }

                if (L > 0.4 || a < 0.25) {
                    element.style.setProperty('color', 'black');
                } else {
                    element.style.setProperty('color', 'white');
                }
            }
        },
        async prepareCommunity() {
            const {community} = this.$router.currentRoute.params;
            if (community !== undefined) {
                const styling = await CommunityService.GetStylingForCommunity();
                if (styling !== null) {
                    if (styling.community !== undefined && styling.community['custom-stylesheet'] !== undefined) {
                        // let stylesheet = document.querySelector(`link[href^="${settings.objectStorage}"]`) as HTMLLinkElement | undefined;
                        // if (!stylesheet) {
                        //     stylesheet = document.createElement('link') as HTMLLinkElement;
                        //     stylesheet.rel = 'stylesheet';
                        //     stylesheet.type = 'text/css';
                        //     document.head.appendChild(stylesheet);
                        // }
                        // stylesheet.href = styling.community['custom-stylesheet'];

                        const stylesheet = document.createElement('link') as HTMLLinkElement;
                        stylesheet.href = styling.community['custom-stylesheet'];
                        stylesheet.rel = 'stylesheet';
                        stylesheet.type = 'text/css';
                        document.head.appendChild(stylesheet);
                    }

                    this.$store.commit('setStyle', styling);
                    this.styled = true;

                    const {style} = document.documentElement;

                    Object.keys(styling.web || {}).forEach((property: string) => {
                        if (property.endsWith('background-image')) {
                            return;
                        }

                        style.setProperty(
                            `--${property.replaceAll('_', '-')}`,
                            styling.web[property],
                        );
                    });
                }
            }
        },
    },
    watch: {
        $route: {
            deep: true,
            handler(to: Route, from: Route) {
                const fromComm = from?.params?.community;
                const toComm = to?.params?.community;

                if (toComm !== fromComm) {
                    this.styled = false;
                    this.prepareCommunity();
                }
            },
        },
        headerColor: {
            deep: true,
            handler(value: string) {
                this.handleHeaderLuminocityChange(value);
            },
        },
    },
    computed: {
        logo(): string | undefined {
            return this.$store.state.styling?.community?.logo;
        },
        title(): string {
            return this.$store.state.title;
        },
        community(): string {
            const {community} = this.$store.getters;
            return community?.slug || '';
        },
        route(): string {
            const {name} = this.$route;
            return name || '';
        },
        animateHeight(): boolean {
            return features.canAnimateHeight;
        },
        headerColor(): string {
            if (this.$store.state.styling?.web === undefined) return '#A6A6A6';
            return this.$store.state.styling.web['header_background-color'] || '#A6A6A6';
        },
    },
    data() {
        return {
            visible: false,
            scrollable: false,
            styled: false,
            communityStylesheet: null as HTMLLinkElement | null,
            isLoading: false,
        };
    },
    created() {
        window.addEventListener('resize', this.resizeEventHandler);
    },
    destroyed() {
        window.removeEventListener('resize', this.resizeEventHandler);
    },
    mounted() {
        this.prepareCommunity();
    },
});
