import InstructionModel, {InstructionModelExt, InstructionType} from '@/models/instruction.model';
import ChatMessageModel, {MessageData, MessageType} from '@/models/chat-message.model';
import ActionModel, {ActionType} from '@/models/action.model';
import AnswerModel from '@/models/answer.model';
import {CrmAuthType} from '@/helpers/constants';

function createElement(tag: string, attributes: object): HTMLElement {
    return Object.assign(document.createElement(tag), attributes);
}
interface ActionDecorator {
    (message: MessageData, action: ActionModel): MessageData;
}

const passThroughDecorator = (message: MessageData) => message;

const actionMessageDecorators: Record<ActionType, ActionDecorator> = {
    payment: (message: MessageData, action: ActionModel): MessageData => (
        action.url
            ? {
                ...message,
                content: createElement('a', {
                    href: action.url,
                    target: '_top',
                    innerText: message.content,
                }).outerHTML,
            }
            : message
    ),
    score: (message: MessageData, action: ActionModel): MessageData => (
        {
            ...message,
            content: message.content?.replace(
                /{\s*(score)\s*}/gi,
                String(action.score),
            ) ?? '',
        }
    ),
    send2con: passThroughDecorator,
    send2api: passThroughDecorator,
    crmAuth: passThroughDecorator,
    crmApi: passThroughDecorator,
    end: passThroughDecorator,
};

export function createInstructionAnswer(
    instruction: InstructionModel,
    answers: AnswerModel[],
): ChatMessageModel | null {
    const type = instruction.question?.type ?? instruction.action?.type ?? null;
    const answer = answers.find(
        (ans: AnswerModel) => ans.instruction === instruction.uuid,
    ) || null;

    return (type !== null && answer !== null)
        ? {
            uuid: `${instruction.uuid}-answer`,
            text: {
                content: String(answer.value),
            },
            rawData: answer.value,
            isReply: true,
            type: type as MessageType,
        }
        : null;
}

export function createInstructionMessage(
    instruction: InstructionModel,
    locale: string,
    fallbackLocale = 'en',
): ChatMessageModel {
    let message = InstructionModelExt.GetMessageForLocale(
        instruction,
        locale,
        fallbackLocale,
    ) as MessageData;

    if (instruction.action) {
        const decorator = actionMessageDecorators[instruction.action.type];
        message = decorator(message, instruction.action);
    }

    return {
        uuid: instruction.uuid,
        text: message,
        isReply: false,
        type: instruction.type as MessageType,
        rawData: message.content,
    };
}

interface InstructionMessageVoter {
    (instruction: InstructionModel): boolean;
}

const acceptVoter: InstructionMessageVoter = () => true;
const denyVoter: InstructionMessageVoter = () => false;

const instructionMessageVoters: Record<InstructionType, InstructionMessageVoter> = {
    'successful-payment': denyVoter,
    action: ({action}: InstructionModel) => (
        Number(action?.params?.crmAuthType) !== CrmAuthType.PreAuthenticated
    ),
    end: denyVoter,
    image: acceptVoter,
    message: acceptVoter,
    video: acceptVoter,
    question: acceptVoter,
};

export function instructionRendersMessage(instruction: InstructionModel): boolean {
    const voter = instructionMessageVoters[instruction.type];
    return voter && voter(instruction);
}

export default function createChatStream(
    instructions: InstructionModel[],
    answers: AnswerModel[],
    locale: string,
    fallbackLocale = 'en',
): ChatMessageModel[] {
    return instructions.reduce(
        (messages: ChatMessageModel[], instr: InstructionModel) => {
            if (!instructionRendersMessage(instr)) {
                return messages;
            }

            messages.push(createInstructionMessage(instr, locale, fallbackLocale));
            const answer = createInstructionAnswer(instr, answers);

            if (answer) {
                messages.push(answer);
            }

            return messages;
        },
        [],
    );
}
