




























// eslint-disable-next-line
// @ts-ignore
import Compress from 'compress.js/src/index';
import QuestionInput from '@/components/answer-input/QuestionInput.vue';

export default QuestionInput.extend({
    name: 'File',
    methods: {
        isAllowedFileSize(file: File): boolean {
            return (file.size / 1024 / 1024) <= 2;
        },
        isAllowedFileExtension(file: File): boolean {
            const [extension] = file.name.toLowerCase().split('.').reverse();
            return Array.from(this.question.options.extensions).includes(extension);
        },
        compressFile(
            file: File,
            quality = 0.75,
            maxWidth = 1080,
            maxHeight = 1920,
        ): Promise<File> {
            const compress = new Compress();
            const options = {
                quality,
                maxWidth,
                maxHeight,
                resize: true,
                rotate: false,
            };

            return new Promise((resolve, reject) => {
                compress
                    .compress([file], options)
                    .catch(reject)
                    .then(
                        ([img]: {data: string; ext: string}[]) => {
                            resolve(
                                Compress.convertBase64ToFile(
                                    img.data,
                                    img.ext,
                                ),
                            );
                        },
                    );
            });
        },
        async handleFileUpload() {
            const input = this.$refs.file as HTMLInputElement;
            const [file] = input.files ?? [];
            if (!file) {
                return;
            }

            if (!this.isAllowedFileExtension(file)) {
                this.triggerError('ERROR.VALIDATION.FILE_EXTENSION');
                return;
            }

            this.$emit('loading', true);

            const compressedFile: File|void = await this
                .compressFile(file)
                .catch(this.onError.bind(this));

            this.$emit('loading', false);

            if (!compressedFile) {
                this.triggerError('ERROR.FILE.COMPRESSION');
                return;
            }

            if (!this.isAllowedFileSize(compressedFile)) {
                this.triggerError('ERROR.VALIDATION.FILE_SIZE');
                return;
            }

            this.submitFile(compressedFile);
        },
        submitFile(file: File): void {
            this.onChange();
            this.$emit('valid', true);
            this.$emit('onFileSuccess', file);

            // Wait for the file and validation to bubble up before submitting the form.
            this.$nextTick(() => {
                this.$emit('submitForm');
            });
        },
        triggerError(error: string): void {
            this.onError(new Error(error));
        },
        onError(error: Error): void {
            this.$emit('valid', false);
            this.$emit('error', error.message);
        },
    },
});
