




import vue, {PropType} from 'vue';
import ChatMessageModel from '@/models/chat-message.model';

export default vue.extend({
    name: 'Message',
    props: {
        message: {
            type: Object as PropType<ChatMessageModel>,
            required: true,
        },
    },
    computed: {
        content(): string {
            return this.message.text.content;
        },
        isReply(): boolean {
            return this.message.isReply;
        },
    },
});
