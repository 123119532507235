






































/* eslint @typescript-eslint/ban-ts-ignore: 0 */
import Vue, {PropType} from 'vue';
import ChatInstruction from '@/components/chat/ChatInstruction.vue';
import InstructionModel from '@/models/instruction.model';
import TypingIndicator from '@/components/chat/TypingIndicator.vue';
import ValidationError from '@/components/chat/ValidationError.vue';
import ChatActions from '@/components/chat/ChatActions.vue';
import ReplyModel from '@/models/reply.model';
import File from '@/components/answer-input/File.vue';
import createAnswer from '@/factories/answer.factory';
import extractAnswer from '@/form/extractor/answer.extractor';
import {debounce} from 'debounce';

export default Vue.extend({
    components: {
        ChatActions,
        ValidationError,
        TypingIndicator,
        ChatInstruction,
    },
    props: {
        instruction: {
            type: Object as PropType<InstructionModel>,
            default: null,
        },
        validationError: {
            type: String,
            default: '',
        },
        isTyping: {
            type: Boolean,
            required: true,
        },
        canGoBack: {
            type: Boolean,
            required: true,
        },
        valid: {
            type: Boolean,
            required: true,
        },
        canReply: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            instructionHeight: '0px',
            file: null as File|null,
            // @ts-ignore Cannot find name 'ResizeObserver'
            resizeObserver: null as ResizeObserver|null,
        };
    },
    mounted(): void {
        // @see https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
        // @ts-ignore Cannot find name 'ResizeObserver'
        this.resizeObserver = new ResizeObserver(this.onResize.bind(this));
        this.resizeObserver.observe(this.$el);
    },
    beforeDestroy(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
            this.resizeObserver = null;
        }
    },
    computed: {
        instructionKey(): string {
            return this.instruction?.uuid ?? 'none';
        },
        canSubmit(): boolean {
            return (
                this.canReply
                && this.valid
                && (
                    this.instruction?.type === 'question'
                    || (
                        this.instruction?.type === 'action'
                        && ['crmApi', 'crmAuth'].includes(
                            this.instruction?.action?.type ?? '',
                        )
                    )
                )
            );
        },
    },
    watch: {
        instructionKey(): void {
            this.recoverInputHeight();
        },
    },
    methods: {
        onFile(file: File): void {
            this.file = file;
        },
        debounceSubmit: debounce(
            (callback: Function) => callback(),
            100,
        ),
        onSubmit(): void {
            this.debounceSubmit(() => {
                this.createReply(this.createFormData()).then((reply: ReplyModel) => {
                    this.$emit('reply', reply);
                    this.file = null;
                });
            });
        },
        createFormData(): FormData {
            if (this.instruction.question?.type === 'file') {
                const formData = new FormData();
                if (this.file) {
                    formData.append('file', this.file as Blob);
                }
                return formData;
            }

            return new FormData(this.$el as HTMLFormElement);
        },
        createReply(formData: FormData): Promise<ReplyModel> {
            return new Promise((resolve, reject) => {
                if (!this.canReply) {
                    reject(new Error('Trying to reply when the application prevents it.'));
                    return;
                }

                if (!this.valid) {
                    reject(new Error('Trying to submit invalid form.'));
                    return;
                }

                const {instruction} = this;
                const formAnswer = extractAnswer(instruction, formData);

                resolve(
                    new ReplyModel(
                        instruction,
                        createAnswer(instruction, formAnswer),
                        formAnswer,
                    ),
                );
            });
        },
        onResize([{contentRect}]: {contentRect: DOMRectReadOnly}[]): void {
            this.$emit('resize', contentRect);
        },
        onInputResize({height}: DOMRectReadOnly): void {
            if (height > 0) {
                this.instructionHeight = `${height}px`;
            }
        },
        recoverInputHeight(): void {
            this.instructionHeight = `min(15vh, ${this.instructionHeight})`;
        },
    },
});
