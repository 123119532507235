
import Chat from '@/views/Chat.vue';
import WindowMessageModel from '@/models/functional/window-message.model';
import InstructionModel from '@/models/instruction.model';
import AnswerModel from '@/models/answer.model';
import ChatService from '@/services/chat.service';
import createChat from '@/factories/chat.factory';

interface Stylesheet {
    [key: string]: string;
}

interface ChatState {
    date: string | Date;
    isTyping: boolean;
    instructions: InstructionModel[];
    answers: AnswerModel[];
    lastAnswered: boolean;
    completed: number;
    validationError: string | null;
    valid: boolean;
    canGoBack: boolean;
}

export default Chat.extend({
    name: 'Preview',
    data(): ChatState {
        return {
            answers: [],
            date: new Date(),
            instructions: [],
            isTyping: false,
            lastAnswered: false,
            completed: 0,
            validationError: null,
            valid: true,
            canGoBack: false,
        };
    },
    computed: {
        canRevert(): boolean {
            return this.canGoBack;
        },
    },
    created() {
        window.addEventListener('message', this.messageHandler);
    },
    destroyed() {
        window.removeEventListener('message', this.messageHandler);
    },
    watch: {
        completed(newCompleted: number): void {
            this.$emit('progress', newCompleted);
        },
    },
    methods: {
        messageHandler(event: MessageEvent) {
            const message = event.data as WindowMessageModel;
            const handlerMap = {
                setTitle: this.setTitle,
                setStyle: this.setStyle,
                updateStyle: this.updateStyle,
                setState: this.setState,
            } as Record<string, (payload: object) => void>;
            const handler = handlerMap[message.type] ?? null;

            if (handler !== null) {
                handler(message.content);
            }
        },
        setTitle(payload: {title: string}): void {
            document.title = payload.title;
        },
        setStyle(payload: {stylesheet: Stylesheet}): void {
            this.updateStyle(payload.stylesheet);
        },
        updateStyle(payload: Stylesheet): void {
            const {style} = document.documentElement;
            const webStyling = this.$store.getters.getWebStyling;
            const styling = JSON.parse(JSON.stringify(this.$store.state.styling)) || {};

            Object.assign(
                styling,
                {
                    web: {
                        ...webStyling || styling.web || {},
                        ...payload,
                    },
                },
            );
            this.$store.commit('setStyle', styling);

            Object.keys(payload).forEach((property: string) => {
                style.setProperty(
                    `--${property.replaceAll('_', '-')}`,
                    payload[property],
                );
            });
        },
        setState(payload: ChatState): void {
            Object.assign(this, payload);
        },
        async createChat(): Promise<ChatService> {
            return createChat('abbi', 'preview');
        },
    },
});
