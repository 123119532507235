















































































































import vue from 'vue';
import ChoiceModel, {getMessageForLocale} from '@/models/choice.model';
import QuestionInput from '@/components/answer-input/QuestionInput.vue';

export default QuestionInput.extend({
    name: 'Choice',
    data() {
        return {
            collapsed: true,
            answer: '',
            active: {} as Record<string, boolean> | string,
            valid: this.skipable,
            // Make sure older iOS devices do not show extra search icon.
            // Applicable to iPhone 13, iOS 15, Safari
            searchFieldType: window.navigator.userAgent.match(/iP(ad|od|hone)/)
                ? 'text'
                : 'search',
            searchTerm: '',
            searchPattern: this.question.options.searchPattern === 'start'
                ? 'start'
                : 'any',
        };
    },
    computed: {
        choices(): ChoiceModel[] {
            return this.question.choices ?? [] as ChoiceModel[];
        },
        activeChoices(): ChoiceModel[] {
            return this.choices.filter(
                (choice: ChoiceModel) => this.isActive(choice.uuid),
            );
        },
        activeMultipleChoices(): ChoiceModel[] {
            return this.isMultiple ? this.activeChoices : [];
        },
        isMultiple() {
            return this.question.type === 'multiple';
        },
        isDropdown() {
            return !!this.question.options.dropdown;
        },
        hasSearch() {
            return (
                !!this.question.options.dropdown
                && !!this.question.options.search
            );
        },
        placeholder(): string {
            return this.question.placeholder as string;
        },
    },
    methods: {
        openDropdown() {
            this.collapsed = true;
        },
        normalizeSearchToken(term: string): string {
            return term
                // Split combined graphemes.
                .normalize('NFD')
                // Remove unusable characters.
                .replace(/[\u0300-\u036f]/g, '')
                // Make case insensitive.
                .toLowerCase();
        },
        matchesSearchTerm(choice: ChoiceModel): boolean {
            const subject = this.normalizeSearchToken(
                this.getCurrentTranslationForChoice(choice)?.content as string,
            ).toLowerCase();
            const term = this.normalizeSearchToken(this.searchTerm);
            const pattern = this.searchPattern === 'any'
                ? new RegExp(`${term}`)
                : new RegExp(`^${term}`);

            return pattern.test(subject);
        },
        toggleDropdown(event: Event) {
            // Do not trigger on chips / search inside the select.
            if (event.target instanceof Element
                && (
                    event.target.closest('.dropdown-input .active')
                    || event.target.closest('.dropdown-input .search')
                )
            ) {
                return;
            }

            this.collapsed = !this.collapsed;

            // Focus on the search input.
            window.setTimeout(
                () => {
                    if (event.target instanceof Element) {
                        const search = event.target.closest('.dropdown-input .search');

                        if (search instanceof HTMLInputElement) {
                            search.focus();
                        }
                    }
                },
                1,
            );
        },
        getCurrentTranslationForChoice(choice: ChoiceModel) {
            return getMessageForLocale(choice, 'en', 'en');
        },
        changeMultipleActiveState($event: Event, uuid: string, idx: number) {
            const choices = this.question.choices || [] as ChoiceModel[];
            const multActive = this.active as Record<string, boolean>;
            if (choices[idx].single) {
                this.choices.forEach((choice: ChoiceModel) => {
                    if (multActive[choice.uuid]) vue.set(multActive, choice.uuid, false);
                });
            } else {
                const isSingleActive = this.choices.map((choice: ChoiceModel) => {
                    const isActive = multActive[choice.uuid];
                    return isActive ? (choice.single) : false;
                });

                if (isSingleActive.indexOf(true) >= 0) {
                    this.choices.forEach((choice: ChoiceModel) => {
                        vue.set(multActive, choice.uuid, false);
                    });
                }
            }

            this.changeActiveState($event, uuid);
        },
        deferChangeActiveState($event: Event, uuid: string) {
            $event.preventDefault();
            window.setTimeout(
                () => this.changeActiveState($event, uuid),
            );
        },
        changeActiveState($event: Event, uuid: string) {
            if (this.isMultiple) {
                const checkboxElement = $event.target as HTMLInputElement;
                vue.set(this.active as Record<string, boolean>, uuid, checkboxElement.checked);
                this.valid = true;

                const {minimum, maximum} = this.question.options;
                const selected = Object.entries(this.active)
                    .filter((entry) => entry[1]);

                if (minimum !== undefined && selected.length < minimum) {
                    this.$emit('error', 'ERROR.VALIDATION.SELECT_AT_LEAST');
                    this.valid = false;
                } else if (maximum !== undefined && selected.length > maximum) {
                    this.$emit('error', 'ERROR.VALIDATION.SELECT_AT_MOST');
                    this.valid = false;
                }

                this.$emit('valid', this.valid);
            } else {
                this.valid = true;
                this.active = uuid;
                this.$emit('valid', this.valid);
            }

            this.onChange();
        },
        isActive(uuid: string): boolean {
            if (this.isMultiple) {
                return (this.active as Record<string, boolean>)[uuid];
            }
            return (this.active as string) === uuid;
        },
    },
});
