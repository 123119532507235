






import vue, {PropType} from 'vue';
import {GetDateAsString as getDateAsString} from '@/helpers/datetime-utilities';

export default vue.extend({
    props: {
        date: {
            type: Date as PropType<Date>,
            required: true,
        },
    },
    computed: {
        formattedDate(): string {
            return getDateAsString(this.date);
        },
    },
});
